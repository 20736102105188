button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #ffcd3c;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
}

/*eigene*/
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 90%;
  margin: 2px auto;
}

.flexbox-container {
  display: flex;
  flex-direction: row;
}

.price-title {
  color: #ff9234;
}

.stand-alone-circle {
  width: 90%;
  margin: 2px auto;
}

.card-unten {
  justify-content: space-between;
}

.allergene {
  color: #968c83;
  font-size: 00.75em;
  font-weight: bold;
}

.bestellung-vormerken {
  margin-bottom: 16px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.space-euro {
  width: 20px;
}

.order-pop {
  margin-left: 4px;
}

.bottom-navigation {
  width: 100%;
  background: #ffcd3c;
  /* background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  ); */
  position: fixed;
  bottom: 0px;
  /* height: 5%; */
}

.navi-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.orders-filter {
  position: sticky;
  height: 40px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.card-title-class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: bold;
  color: #555;
}

.navbar-div {
  background-color: yellow;
  height: 65px;
}

.impressum {
  color: brown;
}

.filter-underlined {
  border-bottom: solid 2px;
  border-bottom-color: coral;
  font-weight: bold;
}

.card-invisible {
}

.bottom-kontaktverfolgung {
  margin-top: 10px;
}

/* .bestellen-mit-popover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #649d66;
} */

.appbar-guest {
  color: #f37121;
  /* #d92027 */
}

.back-arrow {
  position: absolute;
}

.bestell-popover {
  margin-top: 15px;
}

.bestell-button {
  display: flex;
  justify-content: center;
  padding-right: 5px;
}

.bottom-space-div {
  height: 80px;
}
.top-space-div {
  height: 95px;
}

.keine-bestellung-vorhanden {
  text-align: center;
  margin: 15px;
}

.zahl-button {
  margin: 6px;
}

.toggle-order-option {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category-edit-tab {
  font-weight: bold;
}

.category-edit-delete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-btn-cardscompsnap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seven-abstand {
  height: 7px;
}

.welcome-abstand {
  height: 20px;
}

.horizontal-seven {
  width: 7px;
}

.order-fehler-p {
  margin-left: 7px;
  margin-right: 7px;
}

.fehler-rot {
  color: red;
}

.daten-gespeichert {
  color: green;
}

.custom-card {
  max-width: 400px;
  margin: 0 auto;
  border-radius: 5px;
}

.vormerken-button {
  border: 1px solid rgb(22, 165, 22);
  padding: 2px;
  border-radius: 5px;
  font-size: 0.75em;
  white-space: nowrap;
  margin-right: 5px;
}

.card-bu {
  padding: 10px;
}

.single-hr {
  max-width: 400px;
  margin: 0 auto;
}

.description-sc {
  font-size: 00.75em;
  color: #555;
}

.allergen-icon {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  color: #555;
}

.centering {
  width: 40px;
  margin: auto auto;
}

.set-max-width {
  max-width: 600px;
  margin: 10px auto;
}

.login-box {
  padding: 20px;
  max-width: 600px;
  margin: 30px auto;
}

.ten-margin {
  margin-bottom: 10px;
}

.unterstrich {
  text-decoration: underline;
  color: #f37121;
}

.bottom-beschriftung {
  font-size: 00.7em;
  text-align: center;
}

.allergene-neben {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-div {
  margin: 10px;
}

.comment-farbe {
  color: #f37121;
}
.comment-alternativ {
  color: #ffcd3c;
}

.comment-bereit {
  color: #2152f3;
}

.remove-farbe {
  color: #f37121;
}

.allergene-rechts {
  margin-left: auto;
  margin-right: 0;
}

.span-block {
  display: block;
}

.input-width {
  width: 95%;
}

.cancel-icon {
  margin: auto 5px;
}

.styling-languages {
  width: 50%;
  justify-content: space-between;
}

.language-abstand {
  margin-bottom: 10px;
}

.dropdown-div {
  margin-left: 10px;
  color: #968c83;

  font-weight: bold;
}

.language-wahl {
  font-weight: bold;
  text-align: center;
  margin: 10px;
  font-size: 1.2em;
}

.link-aussehen {
  color: #f37121;
  text-decoration: underline;
}

.impressum-schrift {
  font-size: 00.85em;
  color: #555;
  font-weight: bold;
}

.right-left-margin {
  margin: 0px 10px;
}
